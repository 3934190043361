import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { randomRgbBlueColor } from '../Utilities/AppUtilities';

function SystemTypeChart({ data }) {
  const label = [];
  const series = [];
  data.forEach((sys) => {
    if (sys.key) {
      label.push(sys.key.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()));
      series.push(sys.category);
    }
  });
  const options = {
    labels: label,
    theme: {
      monochrome: {
        enabled: false,
      },
    },
    colors: randomRgbBlueColor(data.length),
    legend: {
      position: 'top',
      labels: {
        colors: '#ffffff',
        useSeriesColors: false,
      },
    },
    dataLabels: {
      formatter(val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex];
        return [name.length > 15 ? name.slice(0, 15) + '...' : name, val.toFixed(2) + '%'];
      },
    },
    stroke: {
      show: true,
      width: 0,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -15,
        },
      },
    },
  };

  return <ReactApexChart options={options} series={series} type="pie" height={500} />;
}

export default SystemTypeChart;

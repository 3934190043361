import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function CircularProgessChart({ data }) {
  return (
    <CircularProgressbar
      value={data}
      text={`${data}%`}
      strokeWidth={5}
      styles={buildStyles({
        textColor: '#FE7B5A',
        pathColor: '#FE7B5A',
        trailColor: '#e9ecef',
        textSize: '12px',
      })}
    />
  );
}

export default CircularProgessChart;

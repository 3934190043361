import React, { useEffect, useState } from 'react';
import { Row, Col, ProgressBar, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import './HealthCharts.css';
import VerticalBarChart from '../../Charts/VerticalBarChart';
import { makeRequest } from '../../Services/APIService';
import APIUrlConstants from '../../Config/APIUrlConstants';
import { httpStatusCode } from '../../Constants/TextConstants';
import moment from 'moment';
import { userRoleId } from '../../Utilities/AppUtilities';
import NetworkHealth from '../NetworkHealth/NetworkHealth';
import ProblemCodeChart from '../../Charts/ProblemCodeChart';
import CircularProgessChart from '../../Charts/CircularProgessChart';
import ApexPieChart from '../../Charts/ApexPieChart';
import SystemTypeChart from '../../Charts/SystemTypeChart';

export default function HealthCharts() {
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [q360Data, setq360Data] = useState({});
  const [systemCapacity, setSystemCapacity] = useState({});
  const [systemAvailability, setSystemAvailability] = useState({});
  const roledId = localStorage.getItem('roleId');

  const fetchCharts = async () => {
    const keywords = ['q360_data', 'system_capacity', 'system_availability'];
    let customerName = localStorage.getItem('orgName') || '';
    const customerNumber = localStorage.getItem('orgNo') || '';
    const format = 'YYYY-MM-DD HH:mm:ss';
    const date = moment.utc(new Date()).format(format);
    setIsLoading(true);
    let i;
    i = 0;
    keywords.map(async (type) => {
      let url = '';
      if (type === 'q360_data') {
        url = `${APIUrlConstants.GET_CHARTS_BY_TICKETS}`;
      } else {
        url = `${APIUrlConstants.GET_CHARTS_BY_SYSTEM}`;
        customerName = localStorage.getItem('probe') || '';
      }
      const {
        0: statusCode,
        1: { data },
      } = await makeRequest(`${url}?customerName=${customerName}&customerNumber=${customerNumber}&keyword=${type}&date=${date}`);
      if (httpStatusCode.SUCCESS === statusCode) {
        if (type === 'q360_data') {
          setq360Data(data);
        } else if (type === 'system_capacity') {
          setSystemCapacity(data);
        } else {
          setSystemAvailability(data);
        }
      }
      i += 1;
      if (i === keywords.length) {
        setIsLoading(false);
        setTimeout(() => {
          setReload(!reload);
        }, 300000);
      }
    });
  };

  useEffect(() => {
    if (localStorage.getItem('roleId') === userRoleId.remoteSmartUser) {
      fetchCharts();
    }
  }, [reload]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div className="p-2">
        <p>
          <b>{systemAvailability.title}</b>
        </p>
        <hr />
        <div className="d-flex justify-content-between bg-lightgray p-1">
          <span className="text-signetBlue">Percent Downtime</span>
          <span className="text-signetBlue">
            {systemAvailability.system.systemAvailabilityDTOs[0].percentageDown.toFixed(2)}%
          </span>
        </div>
        <div className="d-flex justify-content-between bg-signetBlue p-1 mt-2">
          <span className="">Percent Uptime</span>
          <span className="">{systemAvailability.system.systemAvailabilityDTOs[0].percentageUp.toFixed(2)}%</span>
        </div>
      </div>
    </Tooltip>
  );

  const renderNoDataFound = () => (
    <div className="cardBody d-flex align-items-center justify-content-center w-100">
      <span className="color-white">No data found</span>
    </div>
  );

  const renderSpinner = () => (
    <div className="cardBody d-flex align-items-center justify-content-center w-100">
      <span className="loader" />
    </div>
  );

  const renderChart = (type, data) => {
    if (type === 'ticketByPriority' && data && data.ticket && data.ticket.ticketPrioritys && data.ticket.ticketPrioritys.length) {
      return (
        <div className="cardBody">
          <VerticalBarChart data={data.ticket.ticketPrioritys} />
          <p className="chartXaxis color-white">Priority</p>
        </div>
      );
    }
    if (type === 'ticketBySite' && data && data.ticket && data.ticket.ticketSites && data.ticket.ticketSites.length) {
      return (
        <div className="cardBody mt-4">
          <ApexPieChart data={data.ticket.ticketSites} />
        </div>
      );
    }
    if (
      type === 'ticketByProblemCode' &&
      data &&
      data.ticket &&
      data.ticket.ticketProblems &&
      data.ticket.ticketProblems.length
    ) {
      return (
        <div className="cardBody">
          <ProblemCodeChart data={data.ticket.ticketProblems} />
          <p className="chartXaxis color-white">Problem Code</p>
        </div>
      );
    }
    if (
      type === 'ticketByCategory' &&
      data &&
      data.ticket &&
      data.ticket.ticketCategories &&
      data.ticket.ticketCategories.length &&
      data.ticket.ticketCategories.filter((sys) => sys.key).length
    ) {
      return (
        <div className="cardBody mt-4">
          <SystemTypeChart data={data.ticket.ticketCategories} />
        </div>
      );
    }
    if (
      type === 'systemCapacity' &&
      data &&
      data.system &&
      data.system.systemCapacityDTOs &&
      data.system.systemCapacityDTOs.length &&
      data.system.systemCapacityDTOs.filter((sys) => sys.capacity !== 0).length
    ) {
      return (
        <div className="cardBody w-100">
          <div className="border-none border-2 w-100 d-flex align-items-center justify-content-center gap-10 flex-wrap">
            {data.system.systemCapacityDTOs.map((sys) => (
              <div key={sys.key} className="circularProgress d-flex flex-column justify-content-center">
                <CircularProgessChart data={sys.capacity.toFixed(2)} />
                <p className="text-center color-white">
                  {sys.key.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())}
                </p>
              </div>
            ))}
          </div>
        </div>
      );
    }
    if (
      type === 'systemCapacityCopy' &&
      data &&
      data.system &&
      data.system.systemCapacityDTOs &&
      data.system.systemCapacityDTOs.length &&
      data.system.systemCapacityDTOs.filter((sys) => sys.capacity !== 0).length
    ) {
      return (
        <div className="cardBody w-100">
          <table className="border-none border-2 w-100 tablebase">
            <thead>
              <tr className="text-center">
                <th className="p-2">System Types</th>
                <th className="p-2">Average Usage</th>
              </tr>
            </thead>
            <tbody>
              {data.system.systemCapacityDTOs.map((sys) => (
                <tr key={sys.capacity}>
                  <td className="p-2 text-12"> {sys.key.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())} </td>
                  <td className="p-2">
                    <ProgressBar className="progressWrap" now={sys.capacity.toFixed(2)} label={Math.floor(sys.capacity) + '%'} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    if (
      type === 'systemAvailability' &&
      data &&
      data.system &&
      data.system.systemAvailabilityDTOs &&
      data.system.systemAvailabilityDTOs &&
      data.system.systemAvailabilityDTOs.length
    ) {
      return (
        <div className="d-flex align-items-center">
          <p className="wrapperProgressTitle color-white">{data.title}</p>
          <div className="wrapperProgress d-flex flex-column w-100">
            <div className="d-flex align-items-center justify-content-center gap-10">
              <span className="capicityInfo mb-1">
                <span className="badge bg-primary primaryColor text-signetBlue rounded-circle">.</span> Percent Uptime
              </span>
              <span className="capicityInfo mb-1">{data.system.systemAvailabilityDTOs[0].percentageUp.toFixed(3)}%</span>
            </div>
            <OverlayTrigger placement="top" delay={{ show: 0, hide: 0 }} overlay={renderTooltip}>
              <ProgressBar
                className="sysAvailProgress"
                now={data.system.systemAvailabilityDTOs[0].percentageUp}
                label={data.system.systemAvailabilityDTOs[0].percentageUp.toFixed(3) + '%'}
              />
            </OverlayTrigger>
          </div>
        </div>
      );
    }
    if (type === 'numberOfTickets' && data && data.ticket && data.ticket.totalOpenTickets) {
      return (
        <div className="cardBody">
          <div className="text-center  d-flex align-items-center flex-column justify-content-center">
            <img className="ticketImg" src={process.env.REACT_APP_PUBLIC_URL + 'images/OpenTicketsGrey150.png'} alt="Ticket" />
            <span className="totalTxt">Open Tickets</span>
            <h3 className="totalCount">{data.ticket.totalOpenTickets}</h3>
          </div>
        </div>
      );
    }
    if (type === 'ticketsByStatus' && data && data.ticket && data.ticket.numberOfTickets) {
      const totalOpenTickets = data.ticket?.totalOpenTickets || 0;
      const totalClosedTickets = data.ticket?.totalClosedTickets || 0;
      const totalInprocessTickets = data.ticket?.totalInprocessTickets || 0;
      const numberOfTickets = data.ticket?.numberOfTickets || 0;
      return (
        <div className="cardBody w-100">
          <div className="chartBase w-100">
            <p className="text-12 progressTitle"> Open </p>
            <div className="p-2 progressBar">
              <ProgressBar
                className="progressWrapTicket"
                variant="success"
                now={((totalOpenTickets / numberOfTickets) * 100).toFixed(2)}
                // label={((totalOpenTickets / numberOfTickets) * 100).toFixed() + '%'}
              />
            </div>
            <p className="text-12 progressValue"> {totalOpenTickets} </p>
          </div>
          <div className="chartBase w-100">
            <p className="text-12 progressTitle"> Inprocess </p>
            <div className="p-2 progressBar">
              <ProgressBar
                className="progressWrapTicket"
                variant="danger"
                now={((totalInprocessTickets / numberOfTickets) * 100).toFixed(2)}
                // label={((totalInprocessTickets / numberOfTickets) * 100).toFixed() + '%'}
              />
            </div>
            <p className="text-12 progressValue"> {totalInprocessTickets} </p>
          </div>
          <div className="chartBase w-100">
            <p className="text-12 progressTitle"> Closed </p>
            <div className="p-2 progressBar">
              <ProgressBar
                className="progressWrapTicket"
                variant="warning"
                now={((totalClosedTickets / numberOfTickets) * 100).toFixed(2)}
                // label={((totalClosedTickets / numberOfTickets) * 100).toFixed() + '%'}
              />
            </div>
            <p className="text-12 progressValue"> {totalClosedTickets} </p>
          </div>
        </div>
      );
    }
    return renderNoDataFound();
  };

  return (
    <div>
      {roledId !== userRoleId.remoteSmartUser ? (
        <NetworkHealth />
      ) : (
        <div className="wrapperBase">
          <div className="wrapHeader mb-4">
            <div className="info">
              <h6>Dashboard</h6>
            </div>
          </div>
          <div>
            <Row>
              <Col lg={6} md={12} sm={12} xs={12} className="mb-4">
                <div className="cardWrapper">
                  <div className="cardHeader d-flex align-items-center justify-content-between">
                    <h6>Tickets by Site</h6>
                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/dashboard/expand.svg'} alt="" />
                  </div>
                  {isLoading ? renderSpinner() : renderChart('ticketBySite', q360Data)}
                </div>
              </Col>

              <Col lg={6} md={12} sm={12} xs={12} className="mb-4">
                <div className="cardWrapper d-flex flex-column justify-content-between">
                  <div className="cardHeader d-flex align-items-center justify-content-between">
                    <h6>Tickets by Priority</h6>
                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/dashboard/expand.svg'} alt="" />
                  </div>
                  <div className="h-100 d-flex flex-column justify-content-center">
                    {isLoading ? renderSpinner() : renderChart('ticketByPriority', q360Data)}
                  </div>
                </div>
              </Col>
              <Col lg={8} md={12} sm={12} xs={12} className="mb-4">
                <div className="cardWrapper heightAuto">
                  <div className="cardHeader d-flex align-items-center justify-content-between">
                    <h6>Top Tickets by Problem Code</h6>
                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/dashboard/expand.svg'} alt="" />
                  </div>
                  {isLoading ? renderSpinner() : renderChart('ticketByProblemCode', q360Data)}
                </div>
              </Col>
              <Col lg={4} md={12} sm={12} xs={12} className="mb-4">
                <div className="cardWrapper">
                  <div className="cardHeader d-flex align-items-center justify-content-between">
                    <h6>Tickets by System Type</h6>
                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/dashboard/expand.svg'} alt="" />
                  </div>
                  {isLoading ? renderSpinner() : renderChart('ticketByCategory', q360Data)}
                </div>
              </Col>
              <Col lg={6} md={12} sm={12} xs={12} className="mb-4">
                <div className="cardWrapper heightAuto">
                  <div className="cardHeader d-flex align-items-center justify-content-between noBM">
                    <h6>Tickets by Status</h6>
                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/dashboard/expand.svg'} alt="" />
                  </div>
                  {isLoading ? renderSpinner() : renderChart('ticketsByStatus', q360Data)}
                </div>
              </Col>
              <Col lg={6} md={12} sm={12} xs={12} className="mb-4">
                <div className="cardWrapper d-flex flex-column justify-content-between">
                  <div className="cardHeader d-flex align-items-center justify-content-between">
                    <h6>System Availability</h6>
                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/dashboard/expand.svg'} alt="" />
                  </div>
                  <div className="h-100 d-flex flex-column justify-content-center">
                    {isLoading ? renderSpinner() : renderChart('systemAvailability', systemAvailability)}
                  </div>
                </div>
              </Col>

              <Col lg={6} md={12} sm={12} xs={12} className="mb-4">
                <div className="cardWrapper d-flex flex-column justify-content-between">
                  <div className="cardHeader d-flex align-items-center justify-content-between">
                    <h6>System Capacity</h6>
                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/dashboard/expand.svg'} alt="" />
                  </div>
                  <div className="h-100 d-flex flex-column justify-content-center">
                    {isLoading ? renderSpinner() : renderChart('systemCapacity', systemCapacity)}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
}

const initialState = {
  ticketList: null,
  isFetching: false,
  isError: false,
};

const TicketReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_TICKET_LIST':
      return {
        ticketList: action.payload,
        isFetching: false,
        isError: false,
      };
    default:
      return state;
  }
};

export default TicketReducer;
